import React from 'react';
import Layout from '../../components/Layout';
import '../../scss/Main.scss';
import ServicePage from '../../components/ServicePage';


function additions_and_renovations({location}) 
{
    return (
        <div className="service0-container">
            <Layout pageName={location.pathname}>
                <ServicePage serviceNumber={0}/>
            </Layout>
        </div>
    )
}

export default additions_and_renovations
